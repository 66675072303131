<template>
  <div class="info">
    <div class="tab flex-start">
      <div class="item center select-tab">商品详情</div>
      <div class="item center">评价</div>
      <div class="item center">常见问题</div>
    </div>
    <div class="book">
      <div class="part">
        <div class="title">基本信息</div>
        <div class="main">
          <div class="row flex-between">
            <div class="item item1">作者：<span>{{info.Writer || '暂无'}}</span></div>
            <div class="item item2">ISBN：{{info.ISBN || '暂无'}}</div>
            <div class="item item3">规格：{{info.Paper}} {{info.Format}}</div>
          </div>
          <div class="row flex-between">
            <div class="item item1">出版社：<span>{{info.PubName || '暂无'}}</span></div>
            <div class="item item2">语言：{{info.Language || '暂无'}}</div>
            <div class="item item3">包装：{{info.Framing || '暂无'}}</div>
          </div>
          <div class="row flex-between">
            <div class="item item1">出版地：{{info.GoodsSource}}</div>
            <div class="item item2">出版日期：{{info.PubDate | timeFilter}}</div>
            <div class="item item3">页数：{{info.Pages || '暂无'}}</div>
          </div>
        </div>
      </div>
      <div class="part">
        <div class="title">简介</div>
        <div class="main">
          <div v-html="introduce"></div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import {timestampToTime} from "@/utils/common"
export default {
  props: {
    info: {
      default: {}
    },
    introduce: {
      default: ''
    }
  },
  filters: {
    timeFilter(val) {
      return timestampToTime(val, 'Y/M/D')
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  width: 1200px;
  border: 1px solid #cccccc;
  .tab {
    width: 100%;
    height: 88px;
    background: #f9f9f9;
    .item {
      width: 158px;
      height: 88px;
      font-size: 20px;
      font-weight: 400;
      color: #202020;
      cursor: pointer;
    }
  }
  .book {
    width: 100%;
    padding: 40px 40px;
    .part {
      width: 100%;
      .title {
        width: 100%;
        height: 40px;
        font-size: 20px;
        color: #0AC8F9;
        font-weight: 600;
        line-height: 40px;
        border-bottom: 1px dotted #d9d9d9;
      }
      .main {
        width: 100%;
        padding: 30px 0 40px 15px;
        font-size: 18px;
        line-height: 24px;
        color: #202020;
        .row {
          width: 100%;
          height: 24px;
          margin-bottom: 5px;
          span {
            color: #0AC8F9;
          }
          .item1 {
            width: 300px;
            height: 24px;
          }
          .item2 {
            width: 300px;
            height: 24px;
          }
          .item3 {
            width: 430px;
            height: 24px;
          }
        }
      }
    }
  }
}
.select-tab {
  background: #FFFFFF;
}
</style>