<template>
  <div class="detail">
    <div class="main">
      <div class="breadcrumb flex-start">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{book.Title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="book-detail flex-between">
        <div class="left">
          <div class="current-pic center">
            <img :src="currentPic" alt="">
          </div>
          <div class="pics">
            <div class="item center" v-for="(item, index) in imgList" :key="index" v-show="item" @click="selectPic(index)">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
        <div class="right">
          <div class="book-title over-hidden2">
            <span>[{{book.GoodsSource}}]</span>
            {{book.Title}}
          </div>
          <div class="des over-hidden2"></div>
          <div class="box">
            <div class="comment flex-column-center">
              <span class="count">{{comments.length}}</span>
              <span class="text">累计评价</span>
            </div>
            <div class="box-item flex-start">
              <div class="box-item-left">价格</div>
              <div class="box-item-right">
                <Price numSize="28" :price="book.SalePrice" />
              </div>
            </div>
            <div class="box-item flex-start">
              <div class="box-item-left">优惠券</div>
              <div class="box-item-right">
                <div class="text"><span class="tag">满减</span>满100减10</div>
              </div>
            </div>
            <div class="box-item flex-start">
              <div class="box-item-left">促销</div>
              <div class="box-item-right">
                <div class="text"><span class="tag">满减</span>满100减10</div>
              </div>
            </div>
            <div class="box-item flex-start">
              <div class="box-item-left">服务</div>
              <div class="box-item-right">
                <div class="text">由 <span class="company" @click="toSeller">{{ currentSeller }}</span> 发货并提供售后服务</div>
              </div>
            </div>
            <div class="box-item flex-start">
              <div class="box-item-left">会员</div>
              <div class="box-item-right">
                <div class="text">会员享受88折</div>
              </div>
            </div>
            <!-- <div class="box-bottom flex-start">
              <div class="box-item-left">服务</div>
              <div class="box-item-right">正品保障 · 放心购买 · 七天无理由退换 · 急速退款</div>
            </div> -->
          </div>
          <div class="operate" style="margin-top: 32px">
            <div class="name">配送至</div>
            <el-select size="mini" v-model="value" placeholder="请选择" style="width: 192px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="operate">
            <div class="name">运费</div>
            <div class="text">满99元免邮</div>
          </div>
          <div class="operate">
            <div class="name">运费</div>
            <el-input-number size="mini" v-model="num" :min="1" :max="10" label="描述文字"></el-input-number>
          </div>
          <div class="btns">
            <button class="buy center">立即购买</button>
            <button class="cart center"  @click="addToCart"><img src="@/assets/cart_white.png">加入购物车</button>
            <button class="store flex-column-center"><img src="@/assets/star.png">收藏</button>
            <button class="store flex-column-center"><img src="@/assets/share.png">分享</button>
          </div>
        </div>
      </div>
      <Like @reload="reloadFunc" />
      <Info :info="baseInfo" :introduce="book.Detail"/>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="show"
      width="30%"
      :before-close="close"
      @close="close"
    >
      <span class="tips"><i class="el-icon-success" style="fontSize: 30px;color: #67C23A;marginRight: 5px"></i> 添加成功</span>
      <div class="success">
        <el-button @click="show = false">继续购物</el-button>
        <el-button type="primary" @click="toShoppingCart">去购物车结算</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getItemDetail, getCoupons, getComments} from "@/api/common"
import Price from "@/components/price"
import Info from "./info.vue"
import Like from "./youlike.vue"
export default {
  name: "detail",
  components: {
    Price,
    Info,
    Like
  },
  data() {
    return {
      id: '',
      book: {},
      imgList: [],
      currentPic: "",
      value: "广东省广州市荔湾区沙面街道",
      num: 1,
      options: [
        {
          value: '广东省广州市荔湾区沙面街道',
          label: '广东省广州市荔湾区沙面街道'
        }
      ],
      baseInfo: {},
      coupons: [],
      comments: [],
      show: false,
      sellerList: ['广东联合图书有限公司', '深圳又日新文化有限公司', '广东大音音像出版社'],
      currentSeller: ""
    }
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.randomFunc();
    this.getDetail();
    this.getCommentList();
  },
  methods: {
    // 随机抓取商家
    randomFunc() {
      let i = parseInt(Math.random()*this.sellerList.length);
      this.currentSeller = this.sellerList[i];
    },
    // 当前页切换商品时
    reloadFunc(id) {
      this.id = id;
      this.getDetail();
      this.randomFunc();
    },
    // 获取商品详情
    async getDetail() {
      let res = await getItemDetail({Id: this.id});
      if(res.IsSuccess) {
        this.book = res.Data;
        this.imgList = [res.Data.MainPic,res.Data.FirstPic,res.Data.SecondPic,res.Data.ThirdPic,res.Data.FourthPic,res.Data.FifthPic];
        this.selectPic(0);
        this.baseInfo = res.Data.Inventory;
      }
    },
    // 切换图片
    selectPic(i) {
      this.currentPic = this.imgList[i];
    },
    // 可用优惠券
    async getCouponsList() {
      let res = await getCoupons();
      console.log(res)
    },
    // 获取评论列表
    async getCommentList() {
      let res = await getComments({
        AuditStatus: 10,
        ItemId: this.id
      });
      if(res.IsSuccess) {
        this.comments = res.Data;
      }
    },
    // 跳转商家入驻
    toSeller() {
      this.$router.push({
        name: "SellerRegister"
      })
    },
    // 加入购物车
    addToCart() {
      let arr = localStorage.cart ? JSON.parse(localStorage.cart) : [];
      arr.push({
        Id: this.book.Id,
        goodsName: this.book.Title,
        goodsPic: this.book.MainPic,
        count: 1,
        price: this.book.SalePrice,
        stock: this.book.Stock
      })
      localStorage.setItem('cart', JSON.stringify(arr));
      this.show = true;
    },
    close() {
      this.show = false
    },
    toShoppingCart() {
      this.$router.push({
        name: "ShoppingCart"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  background: #FFFFFF;
  margin: 0 auto;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 119px;
  .main {
    width: 1200px;
    margin: 0 auto;
    .breadcrumb {
      width: 100%;
      height: 50px;
      align-items: center;
    }
    .book-detail {
      width: 100%;
      .left {
        width: 430px;
        .current-pic {
          width: 100%;
          height: 430px;
          border: 1px solid #eaeaea;
          img {
            max-width: 382px;
            max-height: 382px;
          }
        }
        .pics {
          width: 100%;
          height: 66px;
          margin-top: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          .item {
            width: 66px;
            height: 66px;
            border: 1px solid #eaeaea;
            margin-right: 10px;
            img {
              max-width: 60px;
              max-height: 60px;
            }
          }
        }
      }
      .right {
        width: 710px;
        .book-title {
          width: 100%;
          max-height: 60px;
          font-size: 20px;
          font-weight: 600;
          color: #202020;
          line-height: 30px;
          span {
            color: #0AC8F9;
            margin-right: 5px;
          }
        }
        .des {
          width: 100%;
          max-height: 40px;
          font-size: 14px;
          line-height: 20px;
          color: #939393;
          margin-top: 10px;
        }
        .box {
          width: 100%;
          // height: 222px;
          background: #F9F9F9;
          margin-top: 10px;
          padding: 18px 15px 0;
          position: relative;
          .comment {
            width: 77px;
            height: 46px;
            position: absolute;
            top: 20px;
            right: 0;
            border-left: 1px solid #eaeaea;
            .count {
              font-size: 14px;
              color: #0AC8F9;
              line-height: 20px;
            }
            .text {
              font-size: 12px;
              color: #939393;
              line-height: 20px;
              margin-top: 3px;
            }
          }
          .box-item {
            width: 100%;
            height: 40px;
            align-items: center;
            .box-item-left {
              width: 60px;
              font-size: 12px;
              font-weight: 400;
              color: #939393;
            }
            .box-item-right {
              font-size: 12px;
              color: #202020;
              .text {
                .tag {
                  padding: 2px 5px;
                  color: #FFFFFF;
                  background: #FE0036;
                  border-radius: 2px;
                  border-radius: 2px;
                  overflow: hidden;
                  margin-right: 4px;
                }
                .company {
                  color: #0AC9FA;
                  margin: 0 5px;
                  cursor: pointer;
                }
              }
            }
          }
          .box-bottom {
            width: 100%;
            height: 46px;
            align-items: center;
            border-top: 1px dashed #eaeaea;
            .box-item-left {
              width: 60px;
              font-size: 12px;
              font-weight: 400;
              color: #939393;
            }
            .box-item-right {
              font-size: 12px;
              color: #0AC8F9;
            }
          }
        }
        .operate {
          width: 100%;
          height: 48px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .name {
            width: 75px;
            height: 48px;
            font-size: 12px;
            color: #939393;
            line-height: 48px;
            padding-left: 15px;
          }
          .text {
            height: 48px;
            font-size: 12px;
            color: #202020;
            line-height: 48px;
          }
        }
        .btns {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 30px;
          .buy {
            width: 168px;
            height: 50px;
            border: 1px solid #0AC9FA;
            background: #E2F9FF;
            border-radius: 2px;
            color: #0AC8F9;
            font-size: 20px;
            font-weight: 500;
          }
          .cart {
            width: 168px;
            height: 50px;
            border: 1px solid #0AC9FA;
            background: #0AC9FA;
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 500;
            margin-left: 10px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
          .store {
            width: 50px;
            height: 50px;
            border: 1px solid #CCCCCC;
            border-radius: 2px;
            background: #FFFFFF;
            font-size: 12px;
            color: #585858;
            margin-left: 10px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
.tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.success {
  margin-top: 20px;
  padding-left: 20px;
}
</style>