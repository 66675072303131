//格式化时间
export function timestampToTime(timestamp, format) {
	var date = new Date(timestamp);
	var Y = date.getFullYear();
	var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	var H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	format = format.indexOf('Y') > -1 ? format.replace('Y', Y) : format;
	format = format.indexOf('M') > -1 ? format.replace('M', M) : format;
	format = format.indexOf('D') > -1 ? format.replace('D', D) : format;
	format = format.indexOf('H') > -1 ? format.replace('H', H) : format;
	format = format.indexOf('m') > -1 ? format.replace('m', m) : format;
	format = format.indexOf('s') > -1 ? format.replace('s', s) : format;
	return format
}